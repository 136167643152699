import { useState } from 'react';

import { Button } from '@components/Button';

import customAxios from '@utils/customAxios.tsx';

export const ServicesConveyancingPage = () => {

  const [quotes, setQuotes] = useState([] as { name: string, fees: Fee[] }[]);

  async function getQuotes() {
    const res = await customAxios('/api/home/conveyancing/quote', {
      method: 'POST',
      withCredentials: true,
    });
    const data = await res.data as QuotesData;
    setQuotes(data.quotes.flatMap(it => it.conveyancingQuotes.map(q => {
      const name = it.conveyancerBranch.name;
      const fees = q.legalTransactionFees.map(f => f);
      return ({ name, fees });
    })).sort((a, b) => {
      return a.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0)
        - b.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0);
    }));
  }

  return (
    <div>
      <header className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-8 px-5 pb-12 pt-8">
        <h2 className="flex items-baseline gap-2 text-center text-3xl font-medium md:text-4xl">
          Get a conveyancing quote
        </h2>
        <p className="text-center">
          Home can help find the best conveyance for your needs.
        </p>
      </header>
      <main className="box-border w-full bg-brand px-5 pb-20 pt-8 md:pt-16">
        <div className="mx-auto box-border w-full max-w-6xl">
          <div className="flex w-full flex-col items-center justify-center gap-10 md:gap-14">
            {quotes.length === 0 ? <Button onClick={() => {
              getQuotes();
            }} customStyles="mt-3">
              Get some quotes
            </Button> : <></>}
            {quotes.map(q => (
              <div className="flex flex-col">
                <div className={'w-[80vw] md:w-[400px] bg-amber-200 rounded p-4'}>
                  <div className="text-lg">{q.name}</div>
                  <div className="flex flex-col gap-y-1">
                    {q.fees.map(f => (
                      <div className={'flex justify-between'}>
                        <div className={'self-start'}>{f.name}:</div>
                        <div className={'self-end'}>£{f.netValue}</div>
                      </div>
                    ))}
                    <div className={'flex justify-between'}>
                      <div className={'self-start'}>Total:</div>
                      <div className={'self-end'}>£{q.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0)}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};


type CompanyAddress = {
  'subBuildingNumber': null,
  'subBuildingName': null,
  'buildingNumber': null,
  'buildingName': null,
  'thoroughfare': null,
  'line1': null,
  'line2': null,
  'line3': null,
  'line4': null,
  'locality': null,
  'townOrCity': null,
  'county': null,
  'district': null,
  'postcode': null,
  'country': null,
  'latitude': null,
  'longitude': null
};
type Fee = {
  'name': string | 'Fixed Legal Fees',
  'netValue': number | 850,
  'vatValue': number | 170,
  'grossValue': number | 1020
};
type Quote = {
  'legalTransactionFees': Fee[],
  'estimatedStampDuty': 0,
  'instructionDate': null,
  'referralDate': null,
  'id': string | 'f2cc02b0-428e-4042-af70-ad540c708786',
  'quoteType': 1
};
type Trustpilot = {
  'id': number | 59,
  'rating': number | 0,
  'reviews': number | 0,
  'url': string | 'https://www.trustpilot.com/review/thorntonjones.co.uk'
};
type RegulatoryBody = {
  'id': string | '815236',
  'name': string | 'SRA',
  'logo': string | 'https://assets.nonprod-txn.yourkeys.com/regulators/SRALogo.png',
  'logoUrl': null,
  'url': string | 'https://www.sra.org.uk/consumers/register/organisation/?sraNumber=815236'
};
type Company = {
  'id': number | 0,
  'name': string | 'Thornton Jones Solicitors Ltd',
  'logo': string | 'https://assets.nonprod-txn.yourkeys.com/conveyancers/SRA-815236.png',
  'address': CompanyAddress,
  'phoneNumber': string | null,
  'email': string | null,
  'url': string | 'www.thorntonjones.co.uk',
  'trustpilot': Trustpilot,
  'regulatoryBody': RegulatoryBody,
  'regulatoryId': number | 815236
};
type ConveyancerBranch = {
  'company': Company,
  'id': 9971,
  'name': string | 'Thornton Jones Solicitors Ltd',
  'logo': null,
  'address': CompanyAddress,
  'phoneNumber': string | '01924 290029',
  'iovoxPhoneNumber': string | null,
  'email': string | 'emily@thorntonslegal.com',
  'enquiryEmail': string | null,
  'url': string | 'www.thorntonjones.co.uk'
};
type BranchAndQuotes = {
  'conveyancerBranch': ConveyancerBranch,
  'conveyancingQuotes': Quote[],
  'instructionDate': null,
  'referralDate': null
};

type QuotesData = {
  'quoteGroupId': string | '81cb1b16-b1fa-44e9-8114-589cb5280061',
  'quotes': BranchAndQuotes[],
  'quotesExpired': boolean,
  'canInstruct': boolean,
  'instructionDate': null
};
